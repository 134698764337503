import React from 'react';
import styled from 'styled-components';
import img1 from '../../assets/insta/img1.jpg';
import img2 from '../../assets/insta/img1.jpg';
import img3 from '../../assets/insta/img1.jpg';
import img4 from '../../assets/insta/img1.jpg';
import TitleEffect from '../../components/title-effect';
import insta from '../../assets/icons/instagram.svg';
import Separator from '../separator';
import { useStaticQuery, graphql } from "gatsby"

const Container = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Images = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Image = styled.div`
  background: url(${props => props.src});
  background-size: cover;
  position: relative;
`;

const Img1 = styled(Image)`
  width: 235px;
  height: 235px;
  margin-top: 32px;

  @media(max-width: 800px){
    display: none;
  }
`;

const Img2 = styled(Image)`
  width: 196px;
  height: 196px;
  margin-bottom: 32px;
`;

const Img3 = styled(Image)`
  width: 196px;
  height: 196px;
`;

const Img4 = styled(Image)`
  width: 274px;
  height: 274px;
`;


const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-right: 32px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;

  @media(max-width: 500px){
    margin-right: 16px;
    & > * {
      width: 128px;
      height: 128px;
      margin-bottom: 16px;

    }
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;

  @media(max-width: 500px){
    & > ${Img4} {
      width: 148px;
      height: 148px;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
`;

const InstaIcon = styled.img`
  width: 32px;
  height: 32px;
  filter: invert(98%) sepia(98%) saturate(8%) hue-rotate(182deg) brightness(104%) contrast(104%);
`;

const Subtitle = styled.a`
  display: block;
  font-family: 'GLECB';
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
  text-decoration: none;
  color: black;
`;

// const Insta = () => {
//   const data = useStaticQuery(graphql`
//     {
//       allInstaNode(sort: {fields: timestamp, order: DESC}, limit: 4) {
//         edges {
//           node {
//             id
//             localFile {
//               id
//               childrenImageSharp {
//                 fluid {
//                   src
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   `);

//   const posts = data.allInstaNode.edges.map(edge => edge.node)
//     .map(edge => ({
//       to: `https://www.instagram.com/p/${edge.id}`,
//       src: edge.localFile.childrenImageSharp[0].fluid.src
//     }))

//   const [post1, post2, post3, post4] = posts;



//   return (
//     <Container>
//       <Separator size={48} />
//       <TitleEffect>Suivez nous sur Instagram</TitleEffect>
//       <Subtitle href="https://www.instagram.com/arpeje.store/" target="_blank">@Arpeje</Subtitle>
//       <Images>
//         <Left>
//           <a href={post1.to} target="_blank">
//             <Img1 src={post1.src}>
//               <Overlay>
//                 <InstaIcon src={insta} width={32} height={32} />
//               </Overlay>
//             </Img1>
//           </a>
//         </Left>
//         <Right>
//           <Column>
//             <a href={post2.to} target="_blank">
//               <Img2 src={post2.src}>
//                 <Overlay>
//                   <InstaIcon src={insta} width={32} height={32} />
//                 </Overlay>
//               </Img2>
//             </a>
//             <a href={post3.to} target="_blank">
//               <Img3 src={post3.src}>
//                 <Overlay>
//                   <InstaIcon src={insta} width={32} height={32} />
//                 </Overlay>
//               </Img3>
//             </a>
//           </Column>
//           <a href={post4 ? post4.to : post3.to} target="_blank">
//             <Img4 src={post4 ? post4.src : post3.src}>
//               <Overlay>
//                 <InstaIcon src={insta} width={32} height={32} />
//               </Overlay>
//             </Img4>
//           </a>
//         </Right>
//       </Images>
//     </Container>)
// }

const Insta = () => {
  return <React.Fragment />
}

export default Insta;