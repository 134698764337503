import styled from 'styled-components';
import {Link} from 'gatsby';

const Name = styled(Link)`
  font-family: 'Quentin', 'Quentin2';
  font-size: 2.5rem;
  text-decoration: none;
  color: black;
`;

export default Name;