import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
`;

const RealTitle = styled.div`
  position: relative;
  z-index: 2;
  font-family: 'GLECB','GLECB2';
  font-size: ${props => props.size}rem;
`;

const BackgroundTitle = styled.div`
  color: rgba(241, 238, 233, 0.8);
  font-family: 'Quentin', 'Quentin2';
  font-size: ${props => props.size}rem;
  position: absolute;
  top: ${props => -props.offset}rem;
  width: 100%;
  z-index:1;
  @media(max-width: ${props => props.children.length * 35}px ){
    font-size: ${props => props.size / 2.4}rem;
    top: -0.5rem;
  }
  user-select: none;
`;

const TitleEffect = ({ children, size, hide }) => {
  const backgroundSize = size * 1.5;
  return (
    <Container>
      <RealTitle size={size}>{children}</RealTitle>
      {!hide && <BackgroundTitle size={backgroundSize} offset={size / 2}>{children}</BackgroundTitle>}
    </Container>
  );
}

TitleEffect.defaultProps = {
  size: 3
}

export default TitleEffect;