import React from 'react';
import styled from 'styled-components';
import back from '../../assets/icons/back.svg';
import box from '../../assets/icons/box.svg';
import creditCard from '../../assets/icons/credit-card.svg';
import phone from '../../assets/icons/phone.png';

const Container = styled.div`
  margin-top: 32px;
  width: 100%;
  background-color: rgba(241,238,233,0.6);
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
  align-items: space-arround;
  flex-wrap: wrap;
  @media(min-width: 768px){
    & > * {
      margin-right: 128px;
      margin-left: 128px;
    }
  }
`;

const buyingInformations = [
  { title: "LIVRAISON OFFERTE", logo: box, description: "A partir de 150€ en France via Colissimo" },
  { title: "PAIMENT SECURISE", logo: creditCard, description: "Visa, Mastercard, Maestro, Sofort, BCMC" },
  { title: "RETOUR FACILE", logo: back, description: "Vous disposez de 15 jours pour changer d'avis" },
  { title: "SERVICE CLIENT", logo: phone, description: "Toute l'équipe se tient à votre écoute pour vous guider au mieux", details: "Appel non surtaxé." },
];

const Informations = styled.div`
  margin-top: 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 200px;
`;

const Logo = styled.img`
  height: 96px;

`;

const Title = styled.div`
  text-align: center;
  margin-top: 16px;
  font-weight: 600;
`;

const Description = styled.div`
  text-align: center;
  margin-top: 8px;
`;

const BuyingInformations = () => {
  return (
    <Container>
      {buyingInformations.map((information, index) => {
        return (
          <Informations key={index}>
            <Logo src={information.logo} />
            <Title>{information.title}</Title>
            <Description>{information.description}</Description>
            {information.details && <Description>{information.details}</Description>}
          </Informations>
        );
      })}

    </Container>);
}

export default BuyingInformations;