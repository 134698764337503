import React from 'react';
import styled from 'styled-components';
import Name from './name';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import { Link } from 'gatsby';

const Container = styled.div`
  width: 100%;
  padding-bottom: 128px;
  background-color: rgb(241,238,233);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  & > * {
    margin-right: 64px;
    margin-left: 64px;
  }
`;

const Left = styled.div`
  padding-top: 64px;
`;

const CustomName = styled(Name)`
  font-size: 64px;
`;


const Middle = styled.div`
padding-top: 64px;

`;
const Icons = styled.div`
  margin-bottom: 1.5rem;
`;


const Facebook = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const Instagram = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 16px;
  cursor: pointer;
`;


const Contact = styled.div`
`;

const ContactTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin-bottom: .5em;
`;

const Email = styled.a`
  cursor:pointer;
  font-size: 16px;
  color: black;
  text-decoration: none;
  &:hover {
    font-weight: 600;
  }
`;

const InstagramAddr = styled.a`
  display: block;
  margin-top: 4px;
  cursor:pointer;
  font-size: 16px;
  text-decoration: none;
  color: black;

  &:hover {
    font-weight: 600;
  }
  margin-bottom: 4px;
`;

const PhoneNumber = styled.a`
  cursor: pointer;
  font-size: 16px;
  color: black;
  text-decoration: none;
  &:hover {
    font-weight: 600;
  }
`;


const Address = styled.div`
  margin-top: 1.6em;
  font-size: 16px;
`;


const Right = styled.div`
  padding-top: 64px;

  & > * {
    margin-top: 12px;
  }
`;
const Help = styled(Link)`
  cursor: pointer;
  display: block;
  color: black;
  text-decoration: none;
  &:hover {
    font-weight: 600;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Left>
        <CustomName to="/">Arpèje</CustomName>
      </Left>
      <Middle>
        <Icons>
          <a href="https://www.facebook.com/arpeje.store" target="_blank">
          <Facebook src={facebook} />
          </a>
          <a href="https://www.instagram.com/arpeje.store/" target="_blank">
          <Instagram src={instagram} />
          </a>
        </Icons>

        <Contact>
          <ContactTitle>CONTACT</ContactTitle>
          <Email href="mailto:contact@arpeje.store">Email: contact@arpeje.store</Email>
          <InstagramAddr href="https://www.instagram.com/arpeje.store/" target="_blank">Instagram: @arpeje</InstagramAddr>
          <PhoneNumber href="tel:+33 9 53 51 25 67">Téléphone: 09 53 51 25 67</PhoneNumber>
          <Address>Nos bureaux: Dunkerque, Haut de France</Address>
        </Contact>

      </Middle>
      <Right>
        <Help to="/faq">FAQ</Help>
        <Help to="/cgv">CGV</Help>
      </Right>
    </Container>
  );
}

export default Footer;