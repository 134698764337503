import React from 'react';
import styled from 'styled-components';
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'gatsby';
import Cart from './cart';
import Name from './name';
import Separator from '../separator';

const NavbarContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  z-index: 800;
  background-color: white;
  top: 0;
  padding-left: 10%;
  padding-right: 10%;
  height: 64px;

  @media(max-width: 700px){
    justify-content: flex-end;
  }
`;

const EmptyDiv = styled.div`
  width: 100%;
  height: 60px;
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 32px;
  @media(max-width: 700px){
    display: none;
  }
`;

const MenuLink = styled(Link)`
  font-family: 'GLECB', 'GLECB2';
  font-size: 1.6rem;
  color: black;
  cursor:pointer;
  text-decoration: none;
  &:hover{
    font-style:italic;
  }
`;

var styles = {
  bmBurgerButton: {
    position: 'absolute',
    top: "14px",
    left: "12px",
    width: '36px',
    height: '30px'
  },
  bmBurgerBars: {
    background: '#373a47',
    height: '4px',
    borderRadius: '2px',
  },
  bmBurgerBarsHover: {
    background: '#F1EEE9'
  },
  // bmCrossButton: {
  //   height: '24px',
  //   width: '24px'
  // },
  // bmCross: {
  //   background: '#bdc3c7'
  // },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    left: 0,
    top: 0,
    width: "50%",
  },
  bmMenu: {
    width: "100%",
    background: '#F1EEE9',
    padding: '8px 8px 8px 8px',
    fontSize: '1.15em',
    top: 0,
    position: 'absolute',
    overflow: 'none',
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block'
  },
  bmOverlay: {
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

const BurgerContainer = styled.div`
  width: 100%;
  height: 100%;
  @media(min-width: 700px){
    display: none;
  }
`;

const BurgerBar = styled.div`
  width: 100%;
  height: 3px;
  margin-top: 6px;
  margin-left: 2px;
  background-color: rgb(100, 94, 85);
  border-radius: 4px;
`;

const Burger = () => {
  return (
    <BurgerContainer>
      <BurgerBar/>
      <BurgerBar/>
      <BurgerBar/>
    </BurgerContainer>);
}

const WebsiteTitle = styled(Name)`
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  z-index: 1000;
`;


const Navbar = () => {
  // const metadata = useUserMetadata();

  return (
    <>
      <NavbarContainer>
        <LinksContainer>
          <MenuLink to="/products">Boutique</MenuLink>
          <MenuLink to="/about">A propos</MenuLink>
        </LinksContainer>
        <WebsiteTitle to="/">Arpèje</WebsiteTitle>

        <Menu styles={styles} customBurgerIcon={<Burger/>}>
          <Name to="/">Arpèje</Name>
          <Separator size={16}/>
          <MenuLink id="home" className="menu-item" to="/products">Boutique</MenuLink>
          <Separator size={8}/>
          <MenuLink id="about" className="menu-item" to="/about">A propos</MenuLink>
        </Menu>

        <Cart />
        </NavbarContainer>
      <EmptyDiv />
    </>
  );
};

export default Navbar;