import {useSelector} from 'react-redux';

export const useGlobalState = () => useSelector(state => state);
export const useUserMetadata = () => useSelector(state => state.user.meta);
export const useProductAvailable = (shopifyId) => useSelector(state => {
  if(!shopifyId){
    return {}
  }
  if(!state.products[shopifyId]){
    return {}
  }
  if(!state.products[shopifyId].isAvailable){
    return {}
  }
  return state.products[shopifyId].isAvailable;
});

export const useCheckoutLength = () => useSelector(state => 
  state.checkout
    ? state.checkout.lineItems.reduce((total, {quantity}) => total + quantity, 0)
    : 0
);

export const useCheckout = () => useSelector(state => state.checkout);

export const useIsAdding = () => useSelector(state => state.loading.adding);