import React from 'react';
import styled from 'styled-components';
import Navbar from './navbar';
import Insta from './insta';
import BuyingInformations from './buying-informations';
import Footer from './footer';

const Container = styled.div`
  overflow-x: hidden;
`;

const Layout = ({ disableInsta, children }) => {
  return (
    <Container>
      <Navbar />
      {children}
      {!disableInsta && <Insta />}
      <BuyingInformations />
      <Footer />
    </Container>
  );
}

export default Layout;