import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import bagIcon from '../../assets/icons/bag.svg';
import { useCheckoutLength } from '../../redux/selectors';

const Container = styled(Link)`
  position: relative;
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: black;
  text-decoration: none;
`

const Icon = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const Counter = styled.div`
  background-color: #F1EEE9;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  bottom: -2px;
  right: -2px;
  text-align: center;
`;


const Cart = () => {
  const checkoutLength = useCheckoutLength();
  return (
    <Container to="/checkout">
      <Icon src={bagIcon} />
      <Counter>{checkoutLength}</Counter>
    </Container>
  )
}

export default Cart;